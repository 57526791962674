import React, { Component } from 'react';
import profilepic from '../img/cody.jpg';
import ReactTypingEffect from 'react-typing-effect';
import Social from '../components/Social';


class Home extends Component {
    render() {
        return (

            <div className="condiv home">
                <img src={profilepic} alt="ProfilePic" className="profilepic"></img>
                <ReactTypingEffect className="typingeffect" text={['I am Cody Xiaozhan Yang', 'A data scientist and web developer.']} speed={100} eraseDelay={700}/>
                <Social />
            </div>
            
        )
    }
}

export default Home;