import React, { Component } from 'react';

class Social extends Component {
    render() {
        return (
            <div class="social">
                <a href="https://github.com/xiaozhanyang" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa-github"></i>
                </a>
                <a href="https://linkedin.com/in/xiaozhanyang" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </div>
        )
    }
}

export default Social;
