import React, { Component } from 'react';

import Widecard from '../components/Wildcard';

class Education extends Component {
    render() {
        return (
            <div className="condiv">
                <h1 className="subtopic">My Education</h1>
                <Widecard title="PhD in Robotics Engineering" where="King's College London" from="September 2014" to="January 2019" />
                <Widecard title="MSc in Control Science and Engineering" where="Harbin Institute of Technology" from="September 2011" to="June 2014" />
                <Widecard title="BSc in Electrical Engineering and Automation" where="Shijiazhuang Railway Institute" from="September 2007" to="July 2011" />
            </div>
        )
    }
}

export default Education;