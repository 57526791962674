import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div className="condiv">
                <h1 className="subtopic">About Me</h1>
                <h4>Hey there, </h4>
                <h1>I'm Cody Xiaozhan Yang</h1>
                <h3>An enthusiastic data scientist and curious web developer.</h3>
                <br></br>
                <p>
                    A lovely teammate. An inquisitive, meticulous and proactive specialist in data science and software development. With good programming skills of Python, SQL, JavaScript, HTML/CSS, C/C++, etc, and solid mathematical knowledge as a PhD graduate.
                </p>
            </div>
        )
    }
}

export default About;